<template>
  <div>
    <!-- ===================================
              START ACCOUNT LIST
            ==================================== -->
    <section class="un-navMenu-default without-visit">
      <ul class="nav flex-column">
        <div class="sub-title-pkg">
          <h2>Settings</h2>
        </div>

        <li class="nav-item">
          <div class="nav-link">
            <div class="item-content-link">
              <div class="txt">
                <h3 class="link-title">Dark Mode</h3>
                <p class="size-11 color-text m-0">Browsing in night mode</p>
              </div>
            </div>
            <div class="other-cc">
              <label
                class="switch_toggle toggle_lg theme-switch"
                for="switchDark"
              >
                <input
                  type="checkbox"
                  class="switchDarkMode theme-switch"
                  id="switchDark"
                  aria-describedby="switchDark"
                  aria-label="switchDark"
                />
                <span class="handle"></span>
              </label>
            </div>
          </div>
        </li>

        <div class="sub-title-pkg">
          <h2>Links</h2>
        </div>

        <li class="nav-item">
          <a class="nav-link visited" href="https://token.dinodapps.com">
            <div class="item-content-link">
              <div class="icon bg-green-1 color-green">
                <i class="ri-coin-line"></i>
              </div>
              <h3 class="link-title">Dino Token</h3>
            </div>
            <div class="other-cc">
              <span class="badge-text"></span>
              <div class="icon-arrow">
                <i class="ri-arrow-drop-right-line"></i>
              </div>
            </div>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link visited" href="https://token.dinodapps.com">
            <div class="item-content-link">
              <div class="icon bg-green-1 color-green">
                <i class="ri-hand-coin-line"></i>
              </div>
              <h3 class="link-title">Earn</h3>
            </div>
            <div class="other-cc">
              <span class="badge-text"></span>
              <div class="icon-arrow">
                <i class="ri-arrow-drop-right-line"></i>
              </div>
            </div>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link visited" href="https://whitepaper.dinodapps.com">
            <div class="item-content-link">
              <div class="icon bg-green-1 color-green">
                <i class="ri-file-text-line"></i>
              </div>
              <h3 class="link-title">Whitepaper</h3>
            </div>
            <div class="other-cc">
              <span class="badge-text"></span>
              <div class="icon-arrow">
                <i class="ri-arrow-drop-right-line"></i>
              </div>
            </div>
          </a>
        </li>

        <div class="sub-title-pkg">
          <h2>Support</h2>
        </div>

        <li class="nav-item">
          <a class="nav-link visited" href="page-help.html">
            <div class="item-content-link">
              <div class="icon bg-green-1 color-green">
                <i class="ri-questionnaire-line"></i>
              </div>
              <h3 class="link-title">Help Center</h3>
            </div>
            <div class="other-cc">
              <span class="badge-text"></span>
              <div class="icon-arrow">
                <i class="ri-arrow-drop-right-line"></i>
              </div>
            </div>
          </a>
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/about">
            <div class="item-content-link">
              <div class="icon bg-blue-1 color-blue">
                <i class="ri-information-line"></i>
              </div>
              <h3 class="link-title">About Dino Dapps.</h3>
            </div>
            <div class="other-cc">
              <span class="badge-text"></span>
              <div class="icon-arrow">
                <i class="ri-arrow-drop-right-line"></i>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
