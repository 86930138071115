<template>
  <div>
    <HeaderBack />
    <ShortcutList />
    <Footer />
  </div>
</template>

<script>
import HeaderBack from "@/components/HeaderBack.vue";
import ShortcutList from "@/components/ShortcutList.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Shortcut",
  components: {
    HeaderBack,
    ShortcutList,
    Footer,
  },
  created() {
    this.$mixpanel.track("Visit Shortcut");
  },
};
</script>

<style>
</style>