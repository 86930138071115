var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"un-navMenu-default without-visit"},[_c('ul',{staticClass:"nav flex-column"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/about"}},[_c('div',{staticClass:"item-content-link"},[_c('div',{staticClass:"icon bg-blue-1 color-blue"},[_c('i',{staticClass:"ri-information-line"})]),_c('h3',{staticClass:"link-title"},[_vm._v("About Dino Dapps.")])]),_c('div',{staticClass:"other-cc"},[_c('span',{staticClass:"badge-text"}),_c('div',{staticClass:"icon-arrow"},[_c('i',{staticClass:"ri-arrow-drop-right-line"})])])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-title-pkg"},[_c('h2',[_vm._v("Settings")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"nav-link"},[_c('div',{staticClass:"item-content-link"},[_c('div',{staticClass:"txt"},[_c('h3',{staticClass:"link-title"},[_vm._v("Dark Mode")]),_c('p',{staticClass:"size-11 color-text m-0"},[_vm._v("Browsing in night mode")])])]),_c('div',{staticClass:"other-cc"},[_c('label',{staticClass:"switch_toggle toggle_lg theme-switch",attrs:{"for":"switchDark"}},[_c('input',{staticClass:"switchDarkMode theme-switch",attrs:{"type":"checkbox","id":"switchDark","aria-describedby":"switchDark","aria-label":"switchDark"}}),_c('span',{staticClass:"handle"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-title-pkg"},[_c('h2',[_vm._v("Links")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link visited",attrs:{"href":"https://token.dinodapps.com"}},[_c('div',{staticClass:"item-content-link"},[_c('div',{staticClass:"icon bg-green-1 color-green"},[_c('i',{staticClass:"ri-coin-line"})]),_c('h3',{staticClass:"link-title"},[_vm._v("Dino Token")])]),_c('div',{staticClass:"other-cc"},[_c('span',{staticClass:"badge-text"}),_c('div',{staticClass:"icon-arrow"},[_c('i',{staticClass:"ri-arrow-drop-right-line"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link visited",attrs:{"href":"https://token.dinodapps.com"}},[_c('div',{staticClass:"item-content-link"},[_c('div',{staticClass:"icon bg-green-1 color-green"},[_c('i',{staticClass:"ri-hand-coin-line"})]),_c('h3',{staticClass:"link-title"},[_vm._v("Earn")])]),_c('div',{staticClass:"other-cc"},[_c('span',{staticClass:"badge-text"}),_c('div',{staticClass:"icon-arrow"},[_c('i',{staticClass:"ri-arrow-drop-right-line"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link visited",attrs:{"href":"https://whitepaper.dinodapps.com"}},[_c('div',{staticClass:"item-content-link"},[_c('div',{staticClass:"icon bg-green-1 color-green"},[_c('i',{staticClass:"ri-file-text-line"})]),_c('h3',{staticClass:"link-title"},[_vm._v("Whitepaper")])]),_c('div',{staticClass:"other-cc"},[_c('span',{staticClass:"badge-text"}),_c('div',{staticClass:"icon-arrow"},[_c('i',{staticClass:"ri-arrow-drop-right-line"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-title-pkg"},[_c('h2',[_vm._v("Support")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link visited",attrs:{"href":"page-help.html"}},[_c('div',{staticClass:"item-content-link"},[_c('div',{staticClass:"icon bg-green-1 color-green"},[_c('i',{staticClass:"ri-questionnaire-line"})]),_c('h3',{staticClass:"link-title"},[_vm._v("Help Center")])]),_c('div',{staticClass:"other-cc"},[_c('span',{staticClass:"badge-text"}),_c('div',{staticClass:"icon-arrow"},[_c('i',{staticClass:"ri-arrow-drop-right-line"})])])])])
}]

export { render, staticRenderFns }